
import { Outlet, NavLink } from 'react-router-dom'
import Hamburger from './Hamburger'
import CV from './documents/CV_Vivien2025.pdf'
import { Button } from '@mui/material';
import Avatar from './images/profile-pic.png'



const Navbar = () => {

    // var menuExpanded = document.getElementsByClassName("expanded")[0];

    // menuExpanded.addEventListener('click', handleMenuClick);

    // function handleMenuClick(event) {
    //   if (event.target instanceof HTMLAnchorElement) {
    //     menuExpanded.classList.remove("expanded")
    //   }
    // }

    return (
      <>
        <nav class="nav">
          <div id="header">
          <img class="avatar" src={Avatar}></img>
          <div>
        <h1 id='thats-me'><NavLink to="/" end>Vivien&nbsp;Geschwind</NavLink></h1>
        <div id="job-title">Product Designer</div>
        </div>
        </div>
        <div id="hamburger-visibility">
          <Hamburger/>
        </div>
        <div>
          <ul class="nav desktop">
            <li class='nav-item nav-li '>
              <NavLink to="/" end>Work</NavLink>
            </li>
            <li class='nav-item nav-li '>
              <NavLink to="about">About</NavLink>
            </li>
            <li class='nav-item nav-li '>
            <a href = {CV} target = "_blank">
              CV
              </a>
            </li>
            {/* <li class='nav-li '>
            <Button href = "mailto:vivien-geschwind@live.de">
              Contact
              </Button>
            </li> */}
          </ul>
          </div>
        </nav>
        <Outlet />
      </>
    )
  };

  export default Navbar

